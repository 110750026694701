import ClassicSectionComponent from '@wix/thunderbolt-elements/src/components/ClassicSection/viewer/ClassicSection';


const ClassicSection = {
  component: ClassicSectionComponent
};


export const components = {
  ['ClassicSection']: ClassicSection
};

